import { showToast, Field, CellGroup, Button, showDialog, Popup } from 'vant';
import 'vant/es/toast/style';
import axios from 'axios';
export default {
  data() {
    return {
      price: null,
      message: null,
      dialogshow: false,
      errshow: false,
      err_message: null,
      user_id: null,
      merchantname: "官方门店",
      clicktime: 0
    };
  },
  created() {
    let that = this;
    let user_id = this.$route.query.id;
    this.user_id = user_id;
    //根据用户id获取门店信息
    let url = "https://www.luojiaming.vip/public/index.php/tripartite/unifypay/userdata?id=" + String(user_id);
    axios.get(url).then(function (response) {
      console.log(response.data.merchantname);
      let state = response.data.state;
      if (state == "err") {
        that.err_message = response.data.msg;
        that.errshow = true;
        return;
      } else {
        let merchantname = response.data.merchantname;
        that.merchantname = merchantname;
      }
    }).catch(function (error) {
      console.log(error);
    });
  },
  methods: {
    formatPrice() {
      return '¥' + (this.goods.price / 100).toFixed(2);
    },
    pay() {
      var timestamp = Date.parse(new Date());
      let clicktime = this.clicktime;
      let Difference = timestamp - clicktime;
      console.log(Difference);
      if (Difference < 5000) {
        console.log("点击间隔时间太短保护");
        return;
      } else {
        this.clicktime = timestamp;
      }
      let price = this.price;
      if (!price) {
        console.log("请输入金额");
        showToast('请输入正确金额~');
        return;
      }
      let zhengshu = price % 50;
      if (zhengshu == 0) {
        this.dialogshow = true;
        console.log("是整数啊");
      } else {
        console.log("不是整数啊");
        this.quepay();
      }
    },
    quepay() {
      var that = this;
      let price = this.price;
      if (!price) {
        console.log("请输入金额");
        showToast('请输入正确金额~');
        return;
      }
      let user_id = this.user_id;
      let url = "https://www.luojiaming.vip/tripartite/unifypay/pay?total_fee=" + String(price) + "&id=" + String(user_id);
      axios.get(url).then(function (response) {
        console.log(response);
        let return_code = response.data.return_code;
        if (return_code == "err") {
          that.err_message = response.data.msg;
          that.errshow = true;
          return;
        } else {
          let qrcode = response.data.data.qrcode;
          window.open(qrcode, "_self");
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    cancellation() {
      console.log("确认支付弹框取消");
    }
  }
};